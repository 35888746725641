<template lang="pug">
div
  div
    indicators-points(:indicators="indicators" :chartdataGlobal="chartdataGlobal" :chartdataReturn="chartdataReturn" :chartdataSuggest="chartdataSuggest" :img="img" :colours="colours")
</template>

<script>
import SrvIndicators from '@/services/satisfaction.js'
import chartLine from '@/components/charts/line.vue'
import IndicatorsPoints from '@/components/satisfaction/IndicatorsPoints.vue'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"

export default {
  components: { chartLine, IndicatorsPoints },
  mixins: [commonMixins],
  data() {
    return {
      indicators: [],
      img: {
        help: require('@/assets/img/ico-help.svg')
      },
      loading: true,
      chartdataGlobal: [],
      chartdataReturn: [],
      chartdataSuggest: [],
    }
  },
  created() {
    if (this.$store.getters.getNode > 0) {
      this.getIndicators();
      if (this.indicators.length == 0) {
        this.indicators = this.$store.getters.getIndicators;
        this.generateDatasets();
      }
    }
  },
  watch: {
    filterApply: function () {
      this.getIndicators()
    }
  },
  methods: {
    progressbarWidth: function (points) {
      return 'width:' + (points * 10) + '%'
    },
    getProgressBarClass: function (points) {
      if (points < 4) {
        return 'minimun'
      } else if (points >= 4 && points <= 6) {
        return 'medium'
      } else {
        return 'large'
      }
    },
    // OBTENEMOS INDICADORES
    getIndicators: async function () {
      this.$store.commit('LOADING', true)
      const resIndicators = await SrvIndicators.getSatisfactionIndicators()
      if (resIndicators.status === 200) {
        this.indicators = resIndicators.data
        this.$store.commit(types.SATISFACTION_INDICATORS, resIndicators.data)
        this.generateDatasets();
        this.loading = false
      }

      this.$store.commit('LOADING', false)
    },
    // GENERAMOS CHARDATA PARA LAS GRAFICAS
    generateDatasets: function () {
      let labels = []
      let length = 0
      let datasets = []
      let datasetsReturn = []
      let datasetsSuggest = []
      for (var indicador in this.indicators) {
        length = this.indicators[indicador].daily_return.length
        datasets.push({
          label: this.indicators[indicador].name,
          backgroundColor: this.colours[indicador],
          borderColor: this.colours[indicador],
          fill: false,
          data: this.indicators[indicador].daily_total
        })
        datasetsReturn.push({
          label: this.indicators[indicador].name,
          backgroundColor: this.colours[indicador],
          borderColor: this.colours[indicador],
          fill: false,
          data: this.indicators[indicador].daily_return
        })
        datasetsSuggest.push({
          label: this.indicators[indicador].name,
          backgroundColor: this.colours[indicador],
          borderColor: this.colours[indicador],
          fill: false,
          data: this.indicators[indicador].daily_suggest
        })
      }
      // ETIQUETAS EJE X
      for (var i = 0; i < length; i++) {
        labels.push(this.$moment(this.$store.getters.getDateAfter, "YYYYMMDD").add({ 'days': i }).format('DD/MM/YYYY'))
      }
      this.chartdataGlobal = { labels, datasets }
      this.chartdataReturn = { labels, datasets: datasetsReturn }
      this.chartdataSuggest = { labels, datasets: datasetsSuggest }
    },


  }
}
</script>