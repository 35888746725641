<template lang="pug">
    v-row.px-2.ma-0 
        //GRID TIPO
        div.margin-bottom-0.col-3.d-flex(v-if="typeNode !== 'center'")
            skeleton-card(v-if="isLoading")        
            v-card.gridFull.ma-0(v-if="!isLoading")
                .d-flex.headTitleGrid
                    .block-title.titleGrid
                        translate.mb-0.title-card Incidents
                .card-content
                    .row.text-left
                        .tableGlobal.tableNumeroReclamacionesSugerencias
                            div.headerTableRanking.row.ma-0
                                div.headerItem.col-name
                                    p
                                div.headerItem.col-numero.justify-end
                                    p.mb-0 #[translate Total]
                                div.headerItem.col-tend
                                    p.mb-0 #[translate Tend]                                
                            div.contentTableRanking.row.ma-0
                                p.ml-3(v-if="!claimsCounts") #[translate No data]
                                div.itemContent.col-12.pa-0(v-for="(item, index) in claimsCounts" :key="index")
                                    div.item.col-name
                                        span(v-if="index == 0") {{item.name}}
                                        span.link-text(v-if="index>0" @click="goItemNextLevel(item, item.is_center)") {{item.name}}
                                    div.item.col-numero.text-right
                                        p.mb-0.font-weight-med {{ item.count | formatNumber }}
                                    div.item.col.col-tend
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.count,item.tend_count)")
                                            span {{ item.tend_count | formatNumber }}                                    
        //GRID SENTIMIENTO 
        div.margin-bottom-0.col-9.d-flex(:class="{'col-12': typeNode === 'center' }")
            skeleton-card(v-if="isLoading")        
            v-card.gridFull.ma-0(v-if="!isLoading")
                .d-flex.headTitleGrid
                    .block-title.titleGrid
                        span.mb-0.title-card {{ $gettext('Evolution of the number of incidents') }} 
                .card-content
                    .row.text-left
                        .col-4
                            .tends
                                .tend
                                    translate.text-caption Total number of incidents:
                                    span.text-big.font-weight-med.ml-1 {{ claimsNumber | formatNumber }}
                                    .boxArrowAndText.text-caption
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.arrow(v-bind="attrs" v-on="on" :class="getArrow(claimsNumber,claimsNumberTend)")
                                            span {{ claimsNumberTend | formatNumber }}   
                                        span.mr-1(:style="{color:getColorForTend(claimsNumber,claimsNumberTend)}") {{ claimsNumber | formatNumber }}
                                        translate vs PA
                        .col-4
                            .tends
                                .tend
                                    translate.text-caption Incidents / restaurant: 
                                    span.text-big.font-weight-med.ml-1 {{ claimsNumberRestaurant | formatNumber }}
                                    .boxArrowAndText.text-caption
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.arrow(v-bind="attrs" v-on="on" :class="getArrow(claimsNumberRestaurant,claimsNumberRestaurantTend)")
                                            span {{ claimsNumberRestaurantTend | formatNumber }}
                                        span.mr-1(:style="{color:getColorForTend(claimsNumberRestaurant,claimsNumberRestaurantTend)}") {{ claimsNumberRestaurant | formatNumber }}
                                        translate vs PA
                        .col-12
                            chart-Linear(:chart-data="dataEvolution('claims')" :options="optionsEvolution" :style="{height:'210px'}")                  
        //GRID ORIGEN
        div.margin-bottom-0.col-4
            skeleton-card(v-if="isLoading")        
            v-card.gridFull.ma-0.height-100(v-if="!isLoading")
                .d-flex.headTitleGrid
                    .block-title.titleGrid
                        translate.mb-0.title-card Distribution of incidents by origin 
                .card-content
                    .row.text-left.mt-6
                        .col-12
                            //.boxNumberClaims
                                translate No. of claims: 
                                span.text-big.font-weight-med &nbsp; {{ claimsNumber | formatNumber }}
                            chart-doughnut.boxChartOrigen(:chart-data="dataOrigen('claims')" :options="optionsOrigen" :style="{height:'169px'}")
                            .boxLegendOrigen
                                .legendOrigen.text-body-2(v-for="item in dataLegendOrigen('claims')")
                                    span.boxColor(:style="'background-color:' + item.bg")
                                    span.percent.font-weight-med {{ item.percent | formatNumber(1,2) }}%
                                    span.name {{ item.name }}
                                .groupLegend.mt-4
                                    .legendOrigen.text-body-2(v-for="item in dataLegendOrigenPercent0('claims')")
                                        span.boxColor
                                        span.percent.font-weight-med {{ item.percent | formatNumber(1,2) }}%
                                        span.name {{ item.name }}

        //GRID TIPO
        div.margin-bottom-0.col-8
            skeleton-card(v-if="isLoading")        
            v-card.gridFull.ma-0(v-if="!isLoading")
                .d-flex.headTitleGrid
                    .block-title.titleGrid
                        translate.mb-0.title-card Distribution of incidents by Incident Type 
                .card-content
                    .row.text-left.mt-6
                        .col-4
                            chart-doughnut(:chart-data="dataTipo('claims')" :options="optionsTipo" :style="{height:'210px'}")
                        .col-8
                            .boxLegendTipo
                                .legendTipo.text-body-2(v-for="item in dataLegendTipo('claims')")
                                    span.boxColor(:style="'background-color:' + item.bg")
                                    span.percent.font-weight-med &nbsp; {{ item.percent | formatNumber(1,2) }}%
                                    span.name {{ item.name }}
                                .groupLegend.mt-4
                                    .legendTipo.text-body-2(v-for="item in dataLegendTipoPercent0('claims')")
                                        span.boxColor
                                        span.percent.font-weight-med {{ item.percent | formatNumber(1,2) }}%
                                        span.name {{ item.name }}                     
       
</template>

<script>
import SrvSatisfaction from '@/services/satisfaction.js'
import commonMixins from "@/mixins/common.js"
import SkeletonCard from '@/components/common/skeletonCard.vue'
import chartDoughnut from '@/components/charts/doughnut.vue'
import chartLinear from '@/components/charts/Linear.vue'

export default {
    mixins: [commonMixins],
    components: { SkeletonCard, chartDoughnut, chartLinear }, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX    mixins: [commonMixins],
    data(){
        return {
            data: [],
            colorsOrigen:['#7E2D8E','#FCAE19','#F36627'],
            colorsTipo:['#00C7CA','#FF6950','#E5E5E5','#417505','#F5A623','#9B9B9B','#B4AC52','#9013FE','#FF7700','#4A90E2','#7ED321',
            '#F5F5F5','#F5F5F5','#F5F5F5','#F5F5F5','#F5F5F5','#F5F5F5','#F5F5F5','#F5F5F5','#F5F5F5','#F5F5F5']
        }
    },
    created(){
      this.$store.commit('LOADING', true)
      if(this.$store.getters.getNode > 0){
        this.getEvolution()
      }
    },
    watch: {
      filterApply: function(){
        this.getEvolution()
      }
    },
    computed: {
        claimsCounts () {
            return this.data[0].claims_counts
        },    
        optionsEvolution (){
            return {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: false
                },
                plugins: {
                    datalabels: {
                        display:false
                    }
                },
                scales: {
                    xAxes: [
                        {
                        type: "time",
                        time: {
                            unit: 'day',
                            parser:'DD/MM/YYYY',
                            displayFormats: { day: 'DD/MM/YYYY' },
                            stepSize: 2
                        }
                        }]
                },
                legend: {
                    position: 'left',
                    display:false
                    
                }
            }

        },
        optionsOrigen (){
            return {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: false
                },
                plugins: {
                    datalabels: {
                        display: true,
                        color: '#fff',
                        formatter: function(value) {
                            if (value < 6) {
                                return ''
                            } else {
                                return  Number(value).toLocaleString('de-De', {minimumFractionDigits: 1, maximumFractionDigits: 2}) + '%'
                            }
                        }
                    }
                },
                legend: {
                    display:false,
                    position: 'bottom',
                    align:'start',
                    labels: {
                        padding: 20,
                        fontSize: 16,
                        usePointStyle: true,
                        boxWidth: 10,                
                    }
                },
            }
        },
        optionsTipo (){
            return {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: false
                },
                plugins: {
                    datalabels: {
                        display: true,
                        color: '#fff',
                        formatter: function(value) {
                            if (value < 6) {
                                return ''
                            } else {
                                return  Number(value).toLocaleString('de-De', {minimumFractionDigits: 1, maximumFractionDigits: 2}) + '%'
                            }
                        }
                    }
                },
                legend: {
                    position: 'left',
                    display:false
                    
                }
            }

        },
        claimsNumber (){
            let count
            if(this.data.length > 0){
                count = this.data[0].claims[0].count
            }
            return count
        },
        claimsNumberRestaurant (){
            let count
            if(this.data.length > 0){
                count = this.data[0].claims[0].count_by_restaurant
            }
            return count
        },
        claimsNumberTend (){
            let count
            if(this.data.length > 0){
                count = this.data[0].claims[0].tend_count
            }
            return count
        },
        claimsNumberRestaurantTend (){
            let count
            if(this.data.length > 0){
                count = this.data[0].claims[0].tend_count_by_restaurant
            }
            return count
        }
    },
    methods: {
        formatNumber (val) {
            return this.$options.filters.formatNumber(val,1,2)
        },
        getDataForType (type) {
            let data = {}
            if (type === 'claims') {
                data = this.data[0].claims[0]
            } else if (type === 'suggestions') {
                data = this.data[0].suggestions[0]
            }
            return data
        },
        dataTipo (type){
            let labelsTipo = []
            let dataTipo = []
            let bgColorsTipo = []
            let data = this.getDataForType(type)            
            for(let label in data.kind){
                if(data.kind[label].percent !== null && data.kind[label].percent !== 0){
                    labelsTipo.push(data.kind[label].name)
                    dataTipo.push(data.kind[label].percent)
                    bgColorsTipo.push(this.colorsTipo[label])
                }
            }
            let dataChart = {
                labels: labelsTipo,
                datasets: [
                {
                    backgroundColor: bgColorsTipo,
                    data: dataTipo
                }
                ]  
            }
            return dataChart
        },
        dataLegendTipo (type){
            let labels = []
            let data = this.getDataForType(type)
            for(let label in data.kind){
                if(data.kind[label].percent !== null && data.kind[label].percent !== 0){
                    labels.push({name:data.kind[label].name,percent:data.kind[label].percent,bg:this.colorsTipo[label]})
                }
            }
            return labels
        },
        dataLegendTipoPercent0 (type){
            let labels = []
            let data = this.getDataForType(type)
            for(let label in data.kind){
                if(data.kind[label].percent === null || data.kind[label].percent === 0){
                    labels.push({name:data.kind[label].name,percent:data.kind[label].percent,bg:this.colorsTipo[label]})
                }
            }
            return labels
        },
        dataOrigen (type){
            let labelsOrigen = []
            let dataOrigen = []
            let bgColorsOrigen = []
            let data = this.getDataForType(type)
            for(let label in data.source){
                if(data.source[label].percent !== null && data.source[label].percent !== 0){
                    labelsOrigen.push(data.source[label].name)
                    dataOrigen.push(data.source[label].percent)
                    bgColorsOrigen.push(this.colorsOrigen[label])
                }
            }
            let dataChart = {
                labels: labelsOrigen,
                datasets: [
                {
                    backgroundColor: bgColorsOrigen,
                    data: dataOrigen
                }
                ]  
            }
            return dataChart
        },
        dataLegendOrigenPercent0 (type){
            let labels = []
            let data = this.getDataForType(type)
            for(let label in data.source){
                if(data.source[label].percent === null || data.source[label].percent === 0){
                    labels.push({name: data.source[label].name,percent:data.source[label].percent,bg:this.colorsOrigen[label]})
                }
            }
            return labels
        },
        dataLegendOrigen (type){
            let labels = []
            let data = this.getDataForType(type)
            for(let label in data.source){
                if(data.source[label].percent !== null && data.source[label].percent !== 0){
                    labels.push({name: data.source[label].name,percent: data.source[label].percent,bg:this.colorsOrigen[label]})
                }
            }
            return labels
        },
        dataEvolution (type){
            let labelsEvolution = []
            let dataOrigen = []
            let data = this.getDataForType(type)
            for(let label in  data.daily_count){
                labelsEvolution.push(this.$moment(this.$store.getters.getDateAfter, "YYYYMMDD").add({'days': label}).format('DD/MM/YYYY'))
                dataOrigen.push(data.daily_count[label])
            }
            let dataChart = {
                labels: labelsEvolution,
                datasets: [
                {
                    data: dataOrigen,
                    fill: false,
                    backgroundColor: '#85D9F3',
                    borderColor: '#85D9F3',
                }
                ]  
            }
            return dataChart
        },
        getEvolution() {
            this.$store.commit('LOADING', true)
            SrvSatisfaction.getSatisfactionSacEvolution()
            .then(res => {
                if (res.status === 200) {
                    this.data = res.data
                    this.$store.commit('LOADING', false)
                } 
            }, () => {
            })
        },

    }
}
</script>

<style lang="scss" scoped>
.height-100{
    height: 100%;
}
.boxNumberClaims{
    
    display: block;
    width: 100%;
    max-width: 261px;
    margin: 0 auto;
    margin-bottom:25px;
}
.boxChartOrigen{
    text-align: center;
    margin:0 auto;
}
.legendTipo.percent0:first-child{
            margin-top: 80px;
        }
.boxLegendOrigen{
    display: block;
    width: 100%;
    max-width: 281px;
    margin: 0 auto;
    margin-top:25px;
    .legendOrigen{
        &.percent0:first-child{
            margin-top: 80px;
        }
        .boxColor{
            width: 12px;
            height: 12px;
            border-radius: 12px;
            display: inline-block;
        }
        .percent{   
            margin-left: 2%;  
            width: 19%;
            display: inline-block;
            text-align: right;       
        }
        .name{
            margin-left: 2%;
        }

    }
}
.boxLegendTipo{
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-top:15px;
    .legendTipo{
        &.percent0:nth-child(1){
            margin-top: 80px;
        }
        .boxColor{
            width: 12px;
            height: 12px;
            border-radius: 12px;
            display: inline-block;
        }
        .percent{   
            margin-left: 5px;
            min-width: 10%; 
            display: inline-block;
            text-align: right;    
        }
        .name{
            margin-left: 4%;
            width: 80%;
            display: inline-block;
            vertical-align: top;
        }

    }
}
.tends{
    margin: 30px 0 0 30px;
    .arrow{
        margin-right: 10px;
    }
}
.tableNumeroReclamacionesSugerencias{
        .col-name{
            max-width: 60.33%;
            flex: 0 0 60.33%;
            margin-right: 2.5%;
            padding-left: 10px;
            text-align: left;
            &.textBlackGroup{
                color:#000;                
            }
            &.media-cadena{
                color:#979797;
                span{
                    letter-spacing: -0.02px;
                }
            }
        }
        .col-numero{
            max-width: 18.47%;
            flex: 0 0 18.47%;
            margin-right: 4.5%;
        }
        .col-tend{
            max-width: 10.47%;
            flex: 0 0 10.47%;
        }
    }
</style>