import FeedBackHome from "../views/FeedBack/Home.vue";
import FeedBackOpinions from "../views/FeedBack/Pages/Opinions.vue";
import FeedBackKeywords from "../views/FeedBack/Pages/Keywords.vue";
import FeedBackEmployees from "../views/FeedBack/Pages/Employees.vue";
import FeedBackDish from "../views/FeedBack/Pages/Dish.vue";
import FeedBackDishRanking from "../views/FeedBack/Pages/DishRanking.vue";
import FeedBackBooking from "../views/FeedBack/Pages/Booking.vue";

export default {
  path: "/feedback",
  name: "FeedBackHome",
  component: FeedBackHome,
  redirect: "/feedback/home/:paramCOD?",
  meta: { Auth: true },
  props: true,
  children: [
    {
      path: "home/:paramCOD?",
      name: "FeedBackOpinions",
      component: FeedBackOpinions,
      meta: { Auth: true, loadCategories: true },
      props(route) {
        return route.query || {};
      },
    },
    {
      path: "keywords/:paramCOD?",
      name: "FeedBackKeywords",
      component: FeedBackKeywords,
      meta: { Auth: true, loadCategories: true },
      props(route) {
        return route.query || {};
      },
    },
    {
      path: "employees/:paramCOD?",
      name: "FeedBackEmployees",
      component: FeedBackEmployees,
      meta: { Auth: true, loadCategories: true },
      props(route) {
        return route.query || {};
      },
    },
    {
      path: "dish/:paramCOD?",
      name: "FeedBackDish",
      component: FeedBackDish,
      meta: { Auth: true, loadCategories: true },
      props(route) {
        return route.query || {};
      },
    },
    {
      path: "dish-ranking/:paramCOD?",
      name: "FeedBackDishRanking",
      component: FeedBackDishRanking,
      meta: { Auth: true },
      props(route) {
        return route.query || {};
      },
    },
    {
      path: "booking/:paramCOD?",
      name: "FeedBackBooking",
      component: FeedBackBooking,
      meta: { Auth: true, loadCategories: true },
      props(route) {
        return route.query || {};
      },
    },
  ],
};
