<template lang="pug">
div.boxSectionHeaderAndBody
  div#navtop(:class="{'anchoEscritorioSubnavCerrado': subNavClosed}")
    div.sectionmenu
      div.firstlevel.d-flex
        div(v-if="typeNode === 'center' && ['LocalPosts','LocalServicios','LocalRestaurant','LocalNotifications','LocalConfiguration','LocalAnalytics','LocalAnalyticsGoogle','LocalAnalyticsMeta','LocalMenuProductService', 'LocalAnalyticsDetails', 'LocalAnalyticsMenu','LocalAnalyticsLlamadas','LocalAnalyticsMensajes','LocalAnalyticsReservas','LocalAnalyticsComoLlegar','LocalAnalyticsClics'].includes($route.name)")
          router-link(:to="{name: 'LocalRestaurant', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Ficha]
          router-link(:to="{name: 'LocalNotifications', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Notificaciones]
          router-link(:class="{'router-link-active': $route.path.indexOf('analytics') > -1}" :to="{name: 'LocalAnalytics', params: {paramCOD: this.paramCOD}, query: queryParams}" ) #[translate Estadísticas]

          router-link(v-if="!isAdverbia" :to="{name: 'LocalMenuProductService', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Menú]
          router-link(:to="{name: 'LocalPosts', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Publicaciones]
          router-link(v-if="$store.getters.getCanModifyServicesList" :to="{name: 'LocalServicios', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Servicios]
          router-link(:to="{name: 'LocalConfiguration', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Configuración]
        div(v-if="typeNode !== 'center' || ['LocalList','LocalStatusTasks'].includes($route.name)")
          router-link(:to="{name: 'LocalList', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate General]
          router-link(:to="{name: 'LocalStatusTasks', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Estado de las tareas]
          router-link(:class="{'router-link-active': $route.path.indexOf('analytics') > -1}" :to="{name: 'LocalAnalytics', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Estadísticas]
        div.justify-end.flex-1
          select-country-and-logout
  div.gridSection
    div.sectionmenu
      div.secondlevel(:class="{'d-none': this.$router.history.current.name.indexOf('LocalAnalytics') < 0}")
        .submenuValoration(v-if="$router.history.current.name.indexOf('LocalAnalytics') >= 0 ")
          span
            router-link(:to="{name: 'LocalAnalyticsGoogle', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Google]
            router-link(v-if="isDemoFacebook" :to="{name: 'LocalAnalyticsMeta', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Meta]




      router-view.bg-gray.pt-0   

</template>

<script>
import * as types from '@/store/mutation-types';
import commonMixins from "@/mixins/common.js";
import SelectCountryAndLogout from "@/components/common/SelectCountryAndLogout.vue";
import brandMixin from '@/mixins/brand.js'
export default {
  props: ['paramCOD'],
  mixins: [commonMixins, brandMixin],
  components: { SelectCountryAndLogout },
  created() {
    this.$store.commit(types.UPDATE_SECTION, 5)
  }
};
</script>